import axios from "axios";
import store from "@/store/index.js";
import { parseJWT, mergeObject } from "@/libs/common.js";
import storage from "../storage";

let domain = process.env.VUE_APP_API_DOMAIN;
let namespace = process.env.VUE_APP_API_NAMESPACE;
let baseUrl = `${domain}/${namespace}`
let baseConfig = {
  baseURL: baseUrl,
  timeout: 60000
};
let apiKey = process.env.VUE_APP_API_KEY;

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (Object.prototype.hasOwnProperty.call(response.headers,"x-token")) {
    let jwt = response.headers["x-token"];
    let target = response.config.url.split("/");
    target = target.pop();
    switch (target) {
      case "maintenance":
        break;
      default: {
        store.commit(`update${parseJWT(jwt).role}JWT`, jwt);
        let sessionID =  store.getters.getSessionID;
        storage.common.getItem(sessionID).then((res)=>{
          if (res) {
            res.expiry = parseJWT(jwt).exp;
            storage.common.setItem(sessionID, res);
          }
        })
        
        break;
      }
    }
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default {
  get(url, config = {}) {
    return this.call(url, "get", null, config)
  },
  post(url, data, config = {}) {
    return this.call(url, "post", data, config)
  },
  put(url, data, config = {}) {
    return this.call(url, "put", data, config)
  },
  patch(url, data, config = {}) {
    return this.call(url, "patch", data, config)
  },
  delete(url, data, config = {}) {
    return this.call(url, "delete", null, config)
  },
  call(url, method, data, config = {}) {
    let param = {
      url: url,
      method: method,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF8",
        "Api-Key": apiKey
      }
    };
    
    param = Object.assign(param, baseConfig);
    param = mergeObject(param, config)
    return axios(param);
  }
}