import http from "@/http"

export default  {
  geti18nFile(fileName, origin) {
    return http.get(`static/i18n/${fileName}`, {
      baseURL: origin,
      headers: {
        "Cache-Control": "no-cahe"
      }
    })
  },
  getConfigFile(fileName, origin) {
    return http.get(`static/${fileName}`, {
      baseURL: origin,
      headers: {
        "Cache-Control": "no-cahe"
      }
    })
  }
}
