import storage from "@/storage"

export default {
  state: {
    sessionID: null,
    expiry: null
  },
  getters: {
    getSessionID: state => state.sessionID
  },
  mutations: {
    updateSessionID(state, val) {
      state.sessionID = val
    },
    async updateExpiry(state, val) {
      await storage.common.setItem(state.sessionID, val);
      state.expiry = val;
    },
    resetSessionID(state) {
      state.sessionID = null
    }
  }
}
