import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueI18n from "./language/index.js";
import * as ElIconModules from "@element-plus/icons-vue";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App);

for (let iconName in ElIconModules) {
  app.component(iconName, ElIconModules[iconName]);
}

app.use(store).use(router).use(ElementPlus,{ size: 'large'}).use(VueI18n);

app.mount("#app");

if (process.env.NODE_ENV == "production") {
  var meta = document.createElement("meta");
  meta.httpEquiv = "Content-Security-Policy";
  meta.content = "default-src 'self' https:;style-src 'self';script-src 'self' https://www.gstatic.com https://www.google.com;frame-ancestors 'self' https://www.google.com https://www.gstatic.com https://pay.wepayez.com;img-src 'self' data: blob: https:;worker-src 'self' blob:;child-src blob:;object-src 'self';frame-src https://www.google.com https://www.gstatic.com https://pay.wepayez.com;connect-src 'self' blob: https:;"
  document.head.appendChild(meta)
}

export { app };
