import * as validators from '@vuelidate/validators'
// import { i18n } from "@/i18n"
import $i18n from "@/language/index.js";
import moment from 'moment';
// import { regCheck } from "@/libs/common";
import { i18nPluralization } from "@/libs/common";
import constants from './constants';

import storage from "@/storage"

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = $i18n.global || $i18n

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t })

var checkAgeParams;

// wrap each validator.
export const required = withI18nMessage(validators.required, { messagePath: () => 'validation.required' })

export const requiredWithCustomMessage = (param) => withI18nMessage(validators.required, { messagePath: () => param })

export const requiredIf = (param, args) => withI18nMessage(
  (value)=> {
    let isRequired = param;
    switch(typeof param) {
      case "function":
        isRequired = param.apply(param, args)
        break;
    }
    if(!isRequired) return true;
    return !!value.toString();
  }, { messagePath: () => 'validation.required' })
// export const requiredIf = (param) => withI18nMessage(validators.requiredIf(param), { messagePath: () => 'validation.required' })
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
// export const minLength = withI18nMessage(validators.minLength, { withArguments: true, messagePath: () => 'validation.min', messageParams:($params)=> {return {num: $params.min.min, type: $params.min.type}}})
// or you can provide the param at definition, statically
// export const maxLength = withI18nMessage(validators.maxLength(10))

export const email = withI18nMessage(validators.email, { messagePath: () => 'validation.format.email' })

export const numeric = withI18nMessage(validators.numeric, { messagePath: () => 'validation.lang.N' })

// export const minLength = (min) => { validators.helpers.withMessage( ({
//   $invalid,
//   $params,
//   $model
// }) => `This field has a value of '${$model}' but must have a min length of ${$params.min} so it is ${$invalid ? 'invalid' : 'valid'}`,
// minLength(min))
// }

// export const minLength = (params) => validators.helpers.withMessage(()=>$i18n.global.tc("validation.min", params.num, params), validators.minLength(params.num))

// export const maxLength = (params) => validators.helpers.withMessage(()=>$i18n.global.tc("validation.max", params.num, params), validators.maxLength(params.num))

export const minLength = (params) => validators.helpers.withMessage(()=> {
  return i18nPluralization(
    "validation.min",
    params.limit,
    params.unit
  )
}, validators.minLength(params.limit.num))

export const maxLength = (params) => validators.helpers.withMessage(()=>{
  return i18nPluralization(
    "validation.max",
    params.limit,
    params.unit
  )
}, validators.maxLength(params.limit.num))

export const checkNameLength = (params) => {
  return validators.helpers.withMessage(() => {
    return i18nPluralization(
      $i18n.global.t("validation.name.max"),
      params.limit,
      params.unit
    )
  }, validators.maxLength(params.limit.num))
}

export const checkLang = (params) => withI18nMessage((value) => {
  if (!value) return true;
  let pass = true;
  let reg = null;
  let numberReg = /[0-9]/;
  let regChi = /([\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d])/;
  let regEng = /[A-Za-z]/;
  /* eslint-disable-next-line no-useless-escape */
  let symbolReg = /[&#\-()\[\]',./]/;
  // let symbolReg = /[@#\-!$%^&*()_+|~=`{}\[\]:";'<>?,.\\/]/;

  switch (params.lang) {
    case "C":
      reg = regChi;
      break;
    case "E":
      reg = regEng;
      break;
    case "B": {
      let regString = `${regChi.source}|${regEng.source}`;
      reg = new RegExp("(" + regString + ")");
      break;
    }
  }
  if (params.allowNumber) {
    let regString;
    if (!reg) {
      regString = numberReg.source;
    } else {
      regString = reg.source + "|" + numberReg.source;
    }
    
    reg = new RegExp("(" + regString + ")");
  }
  if (params.allowSymbol) {
    let regString = reg.source + "|" + symbolReg.source;
    reg = new RegExp("(" + regString + ")");
  }
  for (var x = 0; x < value.length; x++) {
    var char = value.charAt(x);
    if (params.allowSpacing && !char.trim()) continue;
    pass = reg.test(char);
    if (!pass) break;
  }
  return pass;
}, { messagePath: () => `validation.lang.${params.lang}${params.allowNumber ? "N" : ""}${params.allowSymbol ? "S" : ""}` })

export const alphaNumeric = withI18nMessage(validators.alphaNum, { messagePath: () => "validation.format.alphaNum" })

// export const exactLength = (params) => validators.helpers.withMessage(()=>$i18n.global.tc("validation.must", params.num, params), (value) => !validators.helpers.req(value) || value.length == params.num)
export const exactLength = (params) => validators.helpers.withMessage(()=>{
  return i18nPluralization(
    "validation.must",
    params.limit,
    params.unit
  )
}, (value) => !validators.helpers.req(value) || value.length == params.limit.num)

export const verifyOctopus = (params) => withI18nMessage(() => {
  if (!params.number || !params.checksum) return false;
  let tmpCheckDigit = 0;
  let idArr = params.number.split("").reverse();
  for (let i = 0; i < idArr.length; i++) {
    let digitVal = Number(idArr[i]);
    switch (i % 2) {
      case 0:
        tmpCheckDigit += digitVal;
        break;
      case 1:
        tmpCheckDigit += Math.floor((digitVal * 2) / 10);
        tmpCheckDigit += (digitVal * 2) % 10;
        break;
    }
  }

  tmpCheckDigit = tmpCheckDigit % 10;
  if (tmpCheckDigit > 0) {
    tmpCheckDigit = 10 - tmpCheckDigit;
  }

  return tmpCheckDigit == params.checksum;
}, { messagePath: () => "validation.octopus"})

export const verifyIdentity = (params) => withI18nMessage(() => {
  if (params.type) return true;
  if (!params.number || !params.checksum) return false;

  let checkSum = 0;
  let validatePass = true;
  let id = `${params.number}${params.checksum}`
  let idArr = id.split("").reverse();
  for (let i = 0; i < 9; i++) {
    if (i == 0) continue;
    let digitVal = Number(idArr[i]);
    switch (i) {
      case 7:
      case 8:
        if (isNaN(digitVal)) {
          if (idArr[i]) {
            digitVal = idArr[i].charCodeAt(0) - 55;
          } else {
            digitVal = 36;
          }
        } else {
          validatePass = false;
        }
        break;
    }
    if (!validatePass) break;
    checkSum += digitVal * (i + 1);
  }
  if (validatePass) {
    let checkSumDigit = 11 - (checkSum % 11);
    checkSumDigit =
      checkSumDigit >= 10
        ? checkSumDigit % 10
          ? "0"
          : "A"
        : checkSumDigit.toString();
    validatePass = checkSumDigit == params.checksum;
  }
  return validatePass;
}, 
{ messagePath: () => {
    return params.type == 0 ? "validation.hkid" : "validation.format.id"
  }
});

export const checkAge = (params) => validators.helpers.withMessage(()=> {
  if (checkAgeParams.type == "between") {
    let from = handleAgeMessage("validation.age.from", checkAgeParams.min);
    let to = handleAgeMessage("validation.age.to", checkAgeParams.max);
    return `${$i18n.global.t("validation.age.text")}${from} ${to}`;
  } else {
    let msg = handleAgeMessage(`validation.age.${checkAgeParams.type}`, checkAgeParams[checkAgeParams.type])
    return $i18n.global.t("validation.age.text") + msg
  }
  // if (params.type == "between") {
  //   return $i18n.global.t("validation.age.text", ) + $i18n.global.tc("validation.age.from", params.min.month, {
  //     year: params.min.year,
  //     month: params.min.month
  //   }) + $i18n.global.tc("validation.age.to", params.max.month, {
  //     year: params.max.year,
  //     month: params.max.month
  //   })
  // } else {
  //   return $i18n.global.t("validation.age.text", ) + $i18n.global.tc(`validation.age.${params.type}`, params[params.type].month, {
  //     year: params[params.type].year,
  //     month: params[params.type].month
  //   })
  // }
  // return $i18n.global.t("validation.dob");
}, (value) => {
  switch (typeof params) {
    case "function":
      checkAgeParams = params.apply(null)
      break;
    default:
      checkAgeParams = params
  }
  let dob = moment(value, "YYYY-MM-DD");
  let pass = true;
  let diff = moment().diff(dob, "months", true)
  switch (params.type) {
    case "min": {
      let min = params.min.year * 12 + params.min.month
      pass = diff >= min
      break;
    }
    case "max": {
      let max = params.max.year * 12 + params.max.month +12
      pass = diff <= max
      break;
    }
    case "between": {
      let min = params.min.year * 12 + params.min.month
      let max = params.max.year * 12 + params.max.month +12
      pass = (diff >= min && diff <= max)
      break;
    }
  }
  return pass;
})

export const checkAddr = (param) => {
  return withI18nMessage(validators.requiredIf(param), { messagePath: () => 'validation.addrEmpty' })
}

export const checkIDLength = (params) => {
  let limits = [{min:8, max:9},{min:4, max:12}];
  return validators.helpers.withMessage(()=> {
    let limit = limits[params.data.type][params.type];
    // return $i18n.global.tc(`validation.${params.type}`, limit, {num: limit, type: "character"})
    return i18nPluralization(
      `validation.${params.type}`,
      { num: limit },
      { num: constants.unit.character }
    )
  }, ()=> {
    let val = params.data.number??"";
    let limit = limits[params.data.type][params.type];
    if(!params.data.type) val += params.data.checksum??"";
    switch (params.type) {
      case "min":
        return val.length >= limit;
      case "max":
        return val.length <= limit;
    }
  })
}

export const confirmDOB = (confirmValue) => {
  return withI18nMessage(validators.sameAs(confirmValue), { messagePath: () => 'validation.dobNotMatched'})
}

// export const checkImageDimensions = (formType) => {
//   return withI18nMessage((dataURL)=>{
//     let result = true;
//     if (formType == "N") {
//       img.onload = function(){
//         var height = img.height;
//         var width = img.width;
//         result = (width / height == 0.75)
//         // code here to use the dimensions
//       }
  
//       img.src = dataURL;
//     }
//     return result;
//   }, {messagePath: () => "validation.image.format"})
// }
export const checkImageFormat = (formType) => {
  return withI18nMessage((dataURL)=>{
    let result = true;
    if (formType == "N") {
      let arr = dataURL.split(";");
      let allowFormat = ["image/png", "image/bmp", "image/jpeg"]
      result = allowFormat.indexOf(arr[0].substring(5)) > -1
    }
    return result;
  }, {messagePath: () => "validation.image.format"})
}

export const checkDocumentFormat = (allowFormatArr, dbName) => {
  return withI18nMessage(async (key)=>{
    if (!key) {
      return true;
    }
    let dataURL = await storage[dbName].getItem(key);
    let result = true;
    if(dataURL) {
      let arr = dataURL.split(";");
      result = allowFormatArr.indexOf(arr[0].substring(5)) > -1
    }
    return result;
  }, {messagePath: () => "validation.document.format"})
}

export const checkEmailDuplication = (param, args) => {
  return withI18nMessage((emailAddr)=>{
    let source = param;
    switch(typeof source) {
      case "function":
        source = param.apply(param, args)
        break;
    }
    if (!emailAddr) {
      return true;
    } else {
      if(source.filter((addr)=>addr == emailAddr).length > 1) {
        return false;
      } else {
        return true;
      }
    }
  }, {messagePath: () => "validation.email.duplication"})
}

export const checkEmailLang = () => {
  return withI18nMessage((value) => {
    if (!value) return true;
    let pass = true;
    let numberReg = /[0-9]/;
    let regEng = /[A-Za-z]/;
    /* eslint-disable-next-line no-useless-escape */
    let symbolReg = /[!#$%&'*+-/=?^_`{|}~@]/;
    // let symbolReg = /[@#\-!$%^&*()_+|~=`{}\[\]:";'<>?,.\\/]/;
    let regString = `${regEng.source}|${numberReg.source}|${symbolReg.source}`;
    let reg = new RegExp("(" + regString + ")");
    for (var x = 0; x < value.length; x++) {
      var char = value.charAt(x);
      pass = reg.test(char);
      if (!pass) break;
    }
    return pass;
  }, { messagePath: () => "validation.lang.ENS" })
}

function handleAgeMessage(path, limit) {
  return i18nPluralization(
    path,
    limit,
    {
      "year": constants.unit.year,
      "month": constants.unit.month
    } 
  )
}
