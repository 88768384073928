import { createRouter, createWebHistory } from "vue-router"
import constants from "@/libs/constants.js"
import $i18n from "@/language/index.js"
import CompanyNew from "@/views/company/New.vue"
import store from "@/store";
import { parseJWT } from "../libs/common";
var _ = require('lodash');

let companyPath = "/:locale/company"
let personalPath = "/:locale/personal"
let registrationpath = "/:locale/registration"
let registrationModificationPath = "/:locale/registration/modify"
const routes = [
  {
    path: "/",
    redirect: {
      name: "Personal",
      params: {
        locale: constants.locale.en
      }
    }
  },
  {
    name: "Home",
    path: "/:locale",
    redirect: to => {
      let locale = to.params.locale
      let locales = constants.locale
      let i18n = $i18n.global
      let path = to.path
      if (Object.prototype.hasOwnProperty.call(locales, locale)) {
        i18n.locale = locale
      } else {
        i18n.locale = locales.en
        path = locale
        // path = `/${locales.en}${to.path}`
      }
      return {
        name: "Process",
        params: {
          locale: i18n.locale,
          part: path
        },
        query: to.query
      }
    }
  },
  {
    name: "Process",
    path: "/:locale/:part",
    redirect: to => {
      let part = to.params.part
      let obj = {
        params: {
          locale: to.params.locale
        },
        query: to.query
      }
      switch (part) {
        case "process":
        case "cancel":
          obj.name = `StepPayment${part.charAt(0).toUpperCase() + part.slice(1)}`
          if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
            obj.query.paymentToken = to.query.token;
            delete obj.query.token;
          }
          if (Object.prototype.hasOwnProperty.call(to.query, "authenToken")) {
            obj.query.token = to.query.authenToken;
            delete obj.query.authenToken;
          }
          break
        case "company":
          obj.name = "Company"
          break
        default:
          obj.name = "Personal"
          break
      }
      return obj
    }
  },
  {
    name: "CompanyLogin",
    path: `${companyPath}/login`,
    component: () => import("@/views/company/Login.vue"),
    redirect: { name: "CompanyLoginEmail" },
    children: [
      {
        name: "CompanyLoginEmail",
        path: "email",
        component: () => import("@/views/company/Email.vue")
      },
      {
        name: "CompanyLoginChallenge",
        path: "challenge/:companyCode/:mail",
        component: () => import("@/views/company/Challenge.vue")
      }
    ]
  },
  {
    name: "CompanyAgreement",
    path: `${companyPath}/agreement`,
    component: () => import("@/views/company/Agreement.vue")
  },
  {
    name: "Company",
    path: companyPath,
    component: () => import("@/views/company/Company.vue"),
    redirect: { name: "CompanyProfile" },
    meta: { type: "Company" },
    children: [
      {
        name: "CompanyProfile",
        path: "",
        component: () => import("@/views/company/Profile.vue")
      },
      {
        name: "CompanyNew",
        path: "new",
        component: CompanyNew,
        meta: { roleRequired: ["A"] },
      },
      {
        name: "CompanyChange",
        path: "change",
        component: CompanyNew,
        meta: { roleRequired: ["A"] },
      },
      {
        name: "CompanyInfoChange",
        path: "infoChange",
        component: () => import("@/views/company/infoChange/InfoChange.vue"),
        redirect: { name: "InfoChangeStart" },
        meta: { roleRequired: ["D", "R"] },
        children: [
          {
            name: "InfoChangeStart",
            path: "",
            component: () => import("@/views/company/infoChange/steps/Start.vue")
          },
          {
            name: "InfoChangeAgreement",
            path: "agreement",
            component: () => import("@/views/company/infoChange/steps/Agreement.vue")
          },
          {
            name: "InfoChangeForm",
            path: "form",
            component: () => import("@/views/company/infoChange/steps/Form.vue")
          },
          {
            name: "InfoChangeConfirm",
            path: "confirm",
            component: () => import("@/views/company/infoChange/steps/Confirmation.vue")
          },
          {
            name: "InfoChangeComplete",
            path: "complete",
            component: () => import("@/views/company/infoChange/steps/Complete.vue")
          },
          {
            path: ":catchAll(.*)",
            beforeEnter: (to) => {
              if(!to.params.catchAll) {
                return {name: "InfoChangeStart", params:{locale: to.params.locale}}
              }
            },
            component: () => import("@/views/404.vue")
          }
        ]
      },
      {
        name: "CompanyDelegation",
        path: "delegate",
        meta: { roleRequired: ["R"] },
        component: () => import("@/views/company/Delegate.vue")
      },
      {
        name: "CompanyEnquiry",
        path: "enquiry",
        meta: { roleRequired: ["A"] },
        component: () => import("@/views/company/Enquiry.vue")
      },
      // {
      // 	name: "CompanyStaff",
      // 	path: "staff",
      // 	// component: () => import("@/views/company/CompanyStaff.vue")
      // 	component: () => import("@/views/company/Enquiry.vue")
      // },
      {
        name: "staffTerminationRecord",
        path: "termination",
        meta: { roleRequired: ["A"] },
        component: () => import("@/views/company/Termination.vue")
      },
      {
        path: ":catchAll(.*)",
        redirect: { name: "CompanyProfile" }
      }
    ]
  },
  {
    name: "Personal",
    path: personalPath,
    component: () => import("@/views/personal/Personal.vue"),
    redirect: { name: "StepStart" },
    meta: { type: "Personal" },
    children: [
      {
        name: "StepStart",
        path: "",
        component: () => import("@/views/personal/steps/Start.vue")
      },
      {
        name: "StepAgreement",
        path: "agreement",
        component: () => import("@/views/personal/steps/Agreement.vue")
      },
      {
        name: "StepForm",
        path: "form",
        component: () => import("@/views/personal/steps/Form.vue")
      },
      {
        name: "StepConfirm",
        path: "confirm",
        component: () => import("@/views/personal/steps/Confirmation.vue")
      },
      {
        name: "StepPayment",
        path: "payment",
        component: () => import("@/views/personal/steps/Payment.vue")
      },
      {
        name: "StepPaymentProcess",
        path: "process",
        component: () => import("@/views/personal/steps/PaymentProcess.vue")
      },
      {
        name: "StepPaymentLanding",
        path: "landing",
        component: () => import("@/views/personal/steps/PaymentLanding.vue")
      },
      {
        name: "StepComplete",
        path: "complete",
        component: () => import("@/views/personal/steps/Complete.vue")
      },
      {
        name: "StepPaymentCancel",
        path: "cancel",
        component: () => import("@/views/personal/steps/PaymentCancel.vue")
      },
      {
        path: ":catchAll(.*)",
        beforeEnter: (to) => {
          if(!to.params.catchAll) {
            return {name: "StepStart", params:{locale: to.params.locale}}
          }
        },
        component: () => import("@/views/404.vue")
      }
      // {
      //   path: ":catchAll(.*)",
      //   redirect: { name: "StepStart" }
      // }
    ]
  },
  {
    name: "PersonalUnauthorized",
    path: `${personalPath}/401`,
    component: () => import("@/views/personal/401.vue")
  },
  {
    name: "PersonalForbidden",
    path: `${personalPath}/403`,
    component: () => import("@/views/personal/403.vue")
  },
  {
    name: "PersonalAppInfo",
    path: `${personalPath}/info`,
    component: () => import("@/views/personal/ApplicationInfo.vue")
  },
  {
    name: "Registration",
    path: registrationpath,
    component: () => import("@/views/registration/Registration.vue"),
    redirect: { name: "RegistrationStart" },
    meta: { type: "Registration" },
    children: [
      {
        name: "RegistrationStart",
        path: "",
        component: () => import("@/views/registration/steps/Start.vue")
      },
      {
        name: "RegistrationAgreement",
        path: "agreement",
        component: () => import("@/views/registration/steps/Agreement.vue")
      },
      {
        name: "RegistrationCompanyInfo",
        path: "companyInfo",
        component: () => import("@/views/registration/steps/CompanyInfo.vue")
      },
      {
        name: "RegistrationResponsible",
        path: "responsible",
        component: () => import("@/views/registration/steps/Responsible.vue")
      },
      {
        name: "RegistrationCoordinator",
        path: "coordinator",
        component: () => import("@/views/registration/steps/Coordinator.vue")
      },
      {
        name: "RegistrationAuthStaff",
        path: "authorizedStaff",
        component: () => import("@/views/registration/steps/AuthorizedStaffs.vue")
      },
      {
        name: "RegistrationConfirm",
        path: "confirm",
        component: () => import("@/views/registration/steps/Confirmation.vue")
      },
      {
        name: "RegistrationComplete",
        path: "complete",
        component: () => import("@/views/registration/steps/Complete.vue")
      },
      {
        path: ":catchAll(.*)",
        beforeEnter: (to) => {
          if(!to.params.catchAll) {
            return {name: "RegistrationStart", params:{locale: to.params.locale}}
          }
        },
        component: () => import("@/views/404.vue")
      }
      // {
      //   path: ":catchAll(.*)",
      //   redirect: { name: "StepStart" }
      // }
    ]
  },
  {
    name: "RegistrationModification",
    path: registrationModificationPath,
    component: () => import("@/views/registrationModification/RegistrationModification.vue"),
    redirect: { name: "RegModStart" },
    meta: { type: "Registration" },
    children: [
      {
        name: "RegModStart",
        path: "",
        component: () => import("@/views/registrationModification/steps/Start.vue")
      },
      {
        name: "RegModAgreement",
        path: "agreement",
        component: () => import("@/views/registrationModification/steps/Agreement.vue")
      },
      {
        name: "RegModForm",
        path: "form",
        component: () => import("@/views/registrationModification/steps/Form.vue")
      },
      {
        name: "RegModConfirm",
        path: "confirm",
        component: () => import("@/views/registrationModification/steps/Confirmation.vue")
      },
      {
        name: "RegModComplete",
        path: "complete",
        component: () => import("@/views/registrationModification/steps/Complete.vue")
      },
      {
        path: ":catchAll(.*)",
        beforeEnter: (to) => {
          if(!to.params.catchAll) {
            return {name: "RegModStart", params:{locale: to.params.locale}}
          }
        },
        component: () => import("@/views/404.vue")
      }
      // {
      //   path: ":catchAll(.*)",
      //   redirect: { name: "StepStart" }
      // }
    ]
  },
  {
    name: "RegModUnauthorized",
    path: `${registrationModificationPath}/401`,
    component: () => import("@/views/registrationModification/401.vue")
  },
  {
    name: "RegModNotAllow",
    path: `${registrationModificationPath}/403`,
    component: () => import("@/views/registrationModification/403.vue")
  },
  // {
  //   path: "/:locale/process",
  //   // redirect: { name: "StepPaymentProcess" },
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // we return a redirect path/location here.
  //     let obj = { name: "StepPaymentProcess", params: to.params, query: to.query }
  //     if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
  //       obj.query.paymentToken = to.query.token;
  //       delete obj.query.token;
  //     }
  //     return obj
  //   }
  // },
  // {
  //   path: "/:locale/cancel",
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // we return a redirect path/location here.
  //     let obj = { name: "StepPaymentCancel", params: to.params, query: to.query }
  //     if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
  //       obj.query.paymentToken = to.query.token;
  //       delete obj.query.token;
  //     }
  //     return obj
  //   }
  //   // redirect: { name: "StepPaymentCancel" },
  // },
  // {
  //   path: "/:locale/:catchAll(.*)",
  //   name: "404",
  //   component: () => import("@/views/404.vue")
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to)
  let locale = to.params.locale
  let locales = constants.locale
  let i18n = $i18n.global

  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    i18n.locale = locale
    if (!!to.meta.type && !! to.meta.roleRequired) {
      if(to.meta.type == "Company") {
        let jwt = store.state.company.jwt;
        if (!jwt)
        {
          next({
            path: `/${locales.en}/company/login/email`
          });
        }
        if (_.intersection(to.meta.roleRequired, parseJWT(store.state.company.jwt)["co-role"].split(";")).length > 0) {
          next()
        } else {
          next ({
            path: `/${locales.en}/company`
          })
        }
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    i18n.locale = locales.en
    next({
      path: `/${locales.en}${to.path}`
    })
  }
})

export default router
