import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import personal from "./personal";
import company from "./company";
import registration from "./registration";
import common from "./common";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    personal,
    company,
    registration,
    common
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "common",
      paths: ["common"]
    }),
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "personal",
      paths: ["personal"]
    }),
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "company",
      paths: ["company"],
    }),
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "registration",
      paths: ["registration"],
    })
  ],
  strict: false,
});
