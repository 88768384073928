// import constants from "@/libs/constants";
import { defaultFileObj, defaultPersonObj, defaultAddressObj } from "../libs/common";
var _ = require('lodash');

const defaultForm = () => {
  return {
    id: null,
    coName: null,
    coNameChi: null,
    mailingAddr: defaultAddressObj(1),
    workLoc: defaultAddressObj(0),
    contact: {
      phone: null,
      fax: null
    },
    chop: defaultFileObj(),
    workLocProof: defaultFileObj(),
    nameChangeProof: defaultFileObj(),
    responPerson: defaultPersonObj(),
    coordinator: defaultPersonObj(),
    authorizedStaffs: [],
    applicant: defaultPersonObj(),
    isCoorEqResp: false,
    isSameAsWorkLoc: false,
    batchUploadKey: null,
    validated: [false, false, false, false]
  };
};

// const defaultNameObj = () => {
//   return {
//     title: 0,
//     firstName: null,
//     surname: null,
//     fullName: null
//   }
// }

// const defaultFileObj = () => {
//   return constants.defaultObj.file
// }

// const defaultPersonObj = () => {
//   return {
//     name: defaultNameObj(),
//     title: null,
//     department: null,
//     mobile: null,
//     email: null
//   }
// }

export default {
  state: {
    locale: null,
    jwt: null,
    acknowledged: null,
    agreement: false,
    complete: false,
    sendEmail: false,
    form: defaultForm(),
    selectedChange: [],
    staffTabIndex: {
      current: -1,
      prev: null
    },
    authenToken: null
  },
  getters: {
    regJWT: state => {
      return state.jwt;
    }
  },
  mutations: {
    updateRegLocale(state, val) {
      state.locale = val
    },
    updateRegForm(state, val) {
      state.form = val;
    },
    updateRegAcknowledged(state, val) {
      state.acknowledged = val;
    },
    updateRegAgreement(state, val) {
      state.agreement = val;
    },
    updateRegFormVerified(state, val) {
      state.verified = val;
    },
    updateAnonymousJWT(state, val) {
      state.jwt = val;
    },
    updateRegAppNo(state, val) {
      state.form.id = val;
    },
    updateRegComplete(state, val) {
      state.complete = val;
    },
    updateRegChangeSelected(state, val) {
      state.selectedChange = val;
    },
    updateRegFormDataByPath(state, obj) {
      let target = state.form;
      console.log(target, obj.path, obj.value)
      _.set(target, obj.path, obj.value);
    },
    updateRegStaffTabIndexCur(state, val) {
      state.staffTabIndex.current = val;
    },
    updateRegStaffTabIndexPrev(state, val) {
      state.staffTabIndex.prev = val;
    },
    updateRegModAuthenToken(state, val) {
      state.authenToken = val
    },
    updateRegFormBatchUploadKey(state, val) {
      state.form.batchUploadKey = val;
    },
    updateRegFormEmailFlag(state, val) {
      state.sendEmail = val;
    },
    updateRegStepValidated(state, obj) {
      state.form.validated[obj.index] = obj.isValidated
    },
    resetRegStepValidated(state) {
      state.form.validated = [false, false, false, false]
    },
    resetRegFormEmailFlag(state) {
      state.sendEmail = false;
    },
    resetRegFormBatchUploadKey(state) {
      state.form.batchUploadKey = null;
    },
    resetRegModAuthenToken(state) {
      state.authenToken = null;
    },
    resetRegFormVerified(state) {
      state.verified = false;
    },
    resetRegStaffTabIndexCur(state) {
      state.staffTabIndex.current = -1
    },
    resetRegStaffTabIndexPrev(state) {
      state.staffTabIndex.prev = null
    },
    resetRegStaffTabIndex(state) {
      state.staffTabIndex = {
        current: -1,
        prev: null
      }
    },
    resetRegChangeSelected(state) {
      state.selectedChange = [];
    },
    resetRegForm(state) {
      let formObj = defaultForm();
      formObj.id = state.form.id
      state.form = formObj;
    },
    resetRegState(state, removeJWT = true) {
      let formObj = defaultForm();
      if(removeJWT) {
        state.jwt = null;
      }
      state.agreement = false;
      state.locale = null;
      state.complete = false;
      state.form = formObj;
      state.acknowledged = null;
      state.agreement = false;
      state.verified = false;
      state.complete = false;
      state.selectedChange = [];
      state.staffTabIndex = {
        current: -1,
        prev: null
      };
      state.authenToken = null;
      state.sendEmail = false;
    },
    resetRegModState(state, removeJWT = true) {
      let formObj = defaultForm();
      if(removeJWT) {
        state.jwt = null;
      }
      state.agreement = false;
      state.locale = null;
      state.complete = false;
      state.form = formObj;
      state.acknowledged = null;
      state.agreement = false;
      state.verified = false;
      state.complete = false;
      state.selectedChange = [];
      state.staffTabIndex = {
        current: -1,
        prev: null
      };
      state.sendEmail = false;
    }

  },
};
