import localforage from "localforage";
import moment from "moment";

// localforage.config({
//   name        : 'fileStorage',
//   storeName   : 'document', // Should be alphanumeric, with underscores.
// });

const common = localforage.createInstance({
  name: "common",
  storeName: 'config'
});

var storage = {
  common: common
}

common.iterate((value, key)=> {
  if (value.dbList.length > 0) {
    value.dbList.forEach(dbName => {
      storage[dbName] = localforage.createInstance({
        name: key,
        storeName: dbName
      });
    });
  }
})

export async function registerCommon(sessionID) {
  let sessionIDList = await storage.common.keys();
  if (sessionIDList.indexOf(sessionID) == -1) {
    await storage.common.setItem(sessionID, {
      expiry: Number(moment().add(65,'m').format("X")),
      dbList: []
    });
  }
}

export async function initInstance(key, name) {
  let res = true;
  try {
    await registerCommon(key);
    
    storage[name] = localforage.createInstance({
      name: key,
      storeName: name
    });

    let record = await storage.common.getItem(key);

    if (record.dbList.indexOf(name) == -1) {
      record.dbList.push(name);
    }
    
    await common.setItem(key, {
      dbList: record.dbList,
      expiry: Number(moment().add(65,'m').format("X"))
    });
  } catch (err) {
    res = false;
  }
  return res;
}

export function dropInstance(key) {
  localforage.dropInstance({
    name: key
  }).then(() => {
    common.removeItem(key);
  });
}

export default storage;