import $i18n from "@/language";
import moment from "moment";
import router from "@/router/index.js";
import * as message from "@/plugins/message";
import constants from "@/libs/constants";
import * as loading from "@/plugins/loading.js";

let agent = navigator.userAgent;
let i18n = $i18n.global;
let company = "company";
let personal = "personal";
let registration = 'registration';
let registrationModification = 'registrationModification';

export const isMoblie = () => {
  return agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1 || agent.indexOf("Android") > -1;
};

export const t_c = function (index, section, key) {
  return t(company, index, section, key);
};

export const t_p = function (index, section, key) {
  return t(personal, index, section, key);
};

export const t_r = function (index, section, key) {
  return t(registration, index, section, key);
};
export const t_m = function (index, section, key) {
  return t(registrationModification, index, section, key);
};

export const tm_c = function (index, section, key) {
  return tm(company, index, section, key);
};

export const tm_p = function (index, section, key) {
  return tm(personal, index, section, key);
};

export const tm_r = function (index, section, key) {
  return tm(registration, index, section, key);
};
export const tm_m = function (index, section, key) {
  return tm(registrationModification, index, section, key);
};

export const t = function (module, index, section, key) {
  return i18n.t(getPath(module, index, section, key));
};

export const tm = function (module, index, section, key) {
  return i18n.tm(getPath(module, index, section, key));
};

function getPath(module, index, section, key) {
  let str = `${module}.${index}.${section}`;
  if (key && key != "") {
    str = `${str}.${key}`;
  }
  return str;
}

export function parseJWT(jwt) {
  var base64Url = jwt.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function authenticateJWT(jwt) {
  if (!jwt) return false;
  let jwtObj = parseJWT(jwt);
  // add 5 mins for default clock skew
  let expiryUnixTimestamp = moment(jwtObj.exp * 1000).add(5, 'm');
  return expiryUnixTimestamp.isAfter(moment());
}

export function createFormSubmissionObj(data) {
  let identityType = data.identity.type;
  let identityNumber = data.identity.number;
  if (!identityType) identityNumber += data.identity.checksum;

  return {
    type: data.type == "R" ? 2 : 1,
    employer: {
      code: data.companyCode,
      staffNo: data.staffId
    },
    personalInformation: {
      identityType: identityType,
      identityNumber: identityNumber,
      name: data.name,
      nameChi: data.nameChi,
      gender: data.gender,
      birthday: moment(new Date(data.birthday)).format("YYYY-MM-DD"),
      mobile: data.mobile,
      eMail: data.email,
      country: "HK",
      officePhone: data.phone
    },
    address: {
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      district: data.district,
      region: data.area
    },
    octopusInformation: {
      cardNumber: data.octopus.number,
      checksum: data.octopus.checksum,
      language: data.language,
      pickupStation: data.pickupStation
    },
    photo: data.photo
  };
}

// export function getNestedObj(obj, ...args) {
//   return args.reduce((obj, level) => obj && obj[level], obj);
// }

export function getNestedObj(obj, arr = []) {
  return arr.reduce((obj, level) => obj && obj[level], obj);
}

export function regCheck(reg, value, spacing) {
  var pass = true;
  for (var x = 0; x < value.length; x++) {
    var char = value.charAt(x);
    if (spacing && !char.trim()) continue;
    pass = reg.test(char);
    if (!pass) break;
  }
  return pass;
}

export async function handleCompanyException(source, noAlert) {
  let msg;
  if (!source?.response) {
    msg = i18n.t("exceptions.default");
  } else {
    if (Object.prototype.hasOwnProperty.call(source.response.data, "ErrorCode")) {
      let code = source.response.data.ErrorCode;
      msg = source.response.data.Message[i18n.locale.charAt(0).toUpperCase() + i18n.locale.slice(1)];

      switch (code) {
        case "INVALID_TOKEN_UPDATE":
          msg = i18n.t("exceptions.recordSubmitted");
          break;
        case "INVALID_DELEGATE_ASSIGNMENT":
          noAlert = true;
          message.WarningAlertWithCallback(i18n.t("exceptions.invalidDelegateAssignment"), "", {
            showClose: false,
            dangerouslyUseHTMLString: true
          })
          break;
        case "EMAIL_EXIST_AUTH_STAFF":
            noAlert = true;
            message.WarningAlertWithCallback(i18n.t("exceptions.emailExistAuthStaff"), "", {
              showClose: false,
              dangerouslyUseHTMLString: true
            })
            break;
        case "MAINTENANCE":
          noAlert = true;
          window.location.href = constants.links.common.maintenance;
          break;
        case "SENDGRID_ERROR":
          msg = i18n.t("exceptions.SendGridFailure");
          router.push({
            name: "CompanyLoginChallenge",
            params: {
              mail: source.loginInfo.email,
              companyCode: source.loginInfo.companyCode
            }
          });
          break;
        case "VALIDATION_NOT_ENGLISH":
          msg = `${i18n.t("company.table.headers.nameShort")}: ${i18n.t("validation.lang.E")}`;
          break;
        case "INVALID_HONGKONG_ID":
          msg = `${i18n.t("company.table.headers.identityNum")}: ${i18n.t("validation.lang.EN")}`;
          break;
      }
    } else {
      msg = i18n.t("exceptions.default");
      switch (source.response.status) {
        case 401: {
          noAlert = true;
          handleCompanySessionExpired();
          // router.push({ name: "CompanyLoginEmail" });
          break;
        }
        default:
          msg = i18n.t("exceptions.default");
          break;
      }
    }
  }
  return handleExceptionMsg(noAlert, msg, source);
}

export function handleExceptionMsg(noAlert, msg, source) {
  if (!noAlert) {
    message.WarningAlert(msg);
  }

  if (getNestedObj(source, ["response", "data"])) {
    return source.response.data;
  }
}

export function goTo(path) {
  router.push({
    name: path
  });
}
export function openLink(path) {
  let link = getLink(path);
  window.open(link, "_blank");
}

export function getLink(path) {
  let source = constants.links;
  let parts = path.split(".");
  // let source = validationRules;
  parts.forEach(key => {
    source = source[key];
  });
  return source;
}

export function handleCompanySessionExpired() {
  message.WarningAlertWithCallback(i18n.t("company.dialog.sessionExpired.message"), i18n.t("company.dialog.sessionExpired.title"), {
    showClose: false,
    callback: () => {
      router.push({ name: "CompanyLoginEmail" });
    }
  });
}

export function isInvalid(invalid, extraClass = "") {
  return `${invalid ? "invalid" : ""} ${extraClass}`;
}

export function triggerValidate(event, source, type) {
  let value = event.target.value.trim();
  switch (type) {
    case "upper":
      value = value.toUpperCase();
      break;
    case "lower":
      value = value.toLowerCase();
      break;
    case "pascal":
      value = value.replace(/\w+/g,
      function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
      break;
  }
  event.target.value = value;
  triggerInput(event.target);
  source.$touch();
}

export function triggerInput(elem) {
  elem.dispatchEvent(new Event("input", { bubbles: true, cancelable: true }));
}

// export function isQRPayment(officialObject) {
//   let res = false;
//   if (officialObject) {
//     if(Object.hasOwnProperty.call(officialObject, "paymentMethod")) {
//       switch (officialObject.paymentMethod) {
//         case "ALIPAYWEBHK":
//         case "ALIPAYWEBCN":
//         case "WECHATWEBQR":
//           res = true;
//           break;
//       }
//     }
//   }
//   return res;
// }

export function handlePaymentResult(resultObj) {
  let result = checkIfBoCPayment(resultObj);
  switch (resultObj.status) {
    case 0:
      if (router.currentRoute.value.name !== "StepPayment") {
        router.push({ name: "StepPayment" });
      }
      break;
    case 1:
      if (result.isBOC) {
        handleBoCPayment(result, resultObj);
        return;
      }
      handlePaymentContinue(resultObj);
      break;
    case 2: {
      if (result.isBOC && result.isCredit) {
        handleBoCPayment(result, resultObj);
        return;
      }
      handlePaymentContinue(resultObj);
      break;
    }
    case 4: {
      router.push({ name: "StepComplete" });
      break;
    }
  }
}

export function checkIfBoCPayment(resultObj) {
  let res = {
    isBOC: false,
    isQR: false,
    isCredit: false,
    timeout: 0
  };
  let officialObject = resultObj.officialObject;
  if (officialObject) {
    if (Object.hasOwnProperty.call(officialObject, "paymentMethod")) {
      switch (officialObject.paymentMethod) {
        case "ALIPAYWEBHK":
        case "ALIPAYWEBCN":
        case "WECHATWEBQR":
        case "FPS":
          res.isBOC = true;
          res.isQR = true;
          res.timeout = 5;
          break;
        case "VISA":
        case "UNIONPAY":
          res.isBOC = true;
          res.isCredit = true;
          res.timeout = 10;
          break;
        case "ALIPAYWAPHK":
        case "ALIPAYWAPCN":
          res.isBOC = true;
          res.timeout = 5;
          break;
      }
    }
  }
  return res;
}

export function handleBoCPayment(checkRes, resultObj) {
  let msgTitle = i18n.t("personal.dialog.creditPayResume.title");
  // let msgContent = i18n.tc("personal.dialog.creditPayResume.message", checkRes.timeout, {min: checkRes.timeout});
  let msgContent = i18nPluralizationBase("personal.dialog.creditPayResume.message", { min: checkRes.timeout });
  let redirectUrl = resultObj.redirectUrl;
  if (checkRes.isCredit) {
    switch (resultObj.officialObject.status) {
      case "Cancel":
        redirectUrl = resultObj.officialObject.paymentKey;
        break;
      case "Void":
      case "Timeout":
        handleFailedPayment();
        return;
    }
  }
  if (checkRes.isCredit && ["Void", "Timeout"].some(status => resultObj.officialObject.status.includes(status))) {
    handleFailedPayment();
    return;
  }

  message.ConfirmDialog(
    msgTitle,
    createPaymentDialogHTMLMsg(msgContent),
    () => {
      if (checkRes.isQR) {
        router.push({
          name: "StepPaymentLanding",
          params: {
            locale: i18n.locale,
            url: redirectUrl
          }
        });
      } else {
        loading.create("process");
        checkBackForwardAction();
        window.location.href = redirectUrl;
      }
    },
    () => {},
    () => {},
    {
      confirmButtonText: i18n.t("common.button.continue"),
      showCancelButton: false,
      showClose: false,
      dangerouslyUseHTMLString: true
    }
  );
}

export function mergeObject(base, input) {
  Object.entries(input).forEach(([key, value]) => {
    if (Object.prototype.hasOwnProperty.call(base, key) && typeof base[key] === "object") {
      base[key] = { ...base[key], ...value };
    } else {
      base[key] = value;
    }
  });
  return base;
}

export function checkBackForwardAction() {
  let checkCount = 0;
  let interval = setInterval(() => {
    if (checkCount == 5) {
      clearInterval(interval);
      window.location.reload();
    }
    checkCount += 1;
  }, 2000);
}

export function handleFailedPayment() {
  message.ConfirmDialog(
    "",
    createPaymentDialogHTMLMsg(i18n.t("exceptions.failPayment")),
    () => {
      router.push({ name: "StepPayment" });
    },
    () => {},
    () => {},
    {
      showClose: false,
      showCancelButton: false,
      confirmButtonText: i18n.t("common.button.continue"),
      dangerouslyUseHTMLString: true
    }
  );
}

export function handleVoidPayment() {
  let msgObj = i18n.tm("exceptions.voidPayment");
  message.ConfirmDialog(
    msgObj.title,
    msgObj.msg,
    () => {
      router.push({ name: "StepPayment" });
    },
    () => {},
    () => {},
    {
      confirmButtonText: i18n.t("common.button.continue"),
      showCancelButton: false,
      cancelButtonText: i18n.t("common.button.changePayment"),
      showClose: false,
      dangerouslyUseHTMLString: true
    }
  );
}

function createPaymentDialogHTMLMsg(msg) {
  let menuBtns = i18n.tm("personal.start.buttons");
  return `<div class='payment-dialog-div'><p>${msg}</h4><p>${i18n.t("personal.dialog.info.message")}</p><ul class='nomargin'>` + `<li><a class='el-link el-link--primary is-underline' href='/${i18n.locale}/personal/info' target='_blank'><span class='el-link--inner'>${menuBtns.info}</span></a></li>` + `<li><a class='el-link el-link--primary is-underline' href='${constants.links.personal.faq}' target='_blank'><span class='el-link--inner'>${menuBtns.faq}</span></a></li></ul></div>`;
}

function handlePaymentContinue(resultObj) {
  if (resultObj.redirectUrl) {
    let msgObj = i18n.tm("personal.dialog.paymentContinue");
    message.ConfirmDialog(
      msgObj.title,
      createPaymentDialogHTMLMsg(msgObj.message),
      () => {
        loading.create("process");
        checkBackForwardAction();
        window.location.href = resultObj.redirectUrl;
      },
      () => {
        router.push({ name: "StepPayment" });
      },
      () => {},
      {
        confirmButtonText: i18n.t("common.button.continue"),
        cancelButtonText: i18n.t("common.button.changePayment"),
        showClose: false,
        dangerouslyUseHTMLString: true
      }
    );
  } else {
    handleFailedPayment();
  }
}

//This is an alternative function for i18n's t(), as the parameter binding of i18n's t() does not work properly after enabling CSP.
export function i18nPluralizationBase(path, replaceObject) {
  let msg = null;
  if (process.env.NODE_ENV == "production") {
    msg = $i18n.global.t(path);
    for (const [key, value] of Object.entries(replaceObject)) {
      msg = msg.replace(`{${key}}`, value);
    }
  } else {
    msg = $i18n.global.t(path, replaceObject);
  }
  return msg;
}

//This is an alternative function for i18n's tc(), as the parameter binding of i18n's tc() does not work properly after enabling CSP.
// export function i18nPluralization(sourceMsg, limit, unit) {
//   let limitKeys = Object.values(limit).filter(num => num > 0);
//   let msg = sourceMsg.split("|")[limitKeys.length - 1];
//   for (const [key, value] of Object.entries(limit)) {
//     let numTarget = "num";
//     let unitTarget = "unit";
//     if (limitKeys.length > 1) {
//       numTarget += `-${key}`;
//       unitTarget += `-${key}`;
//     }
//     if (unit) {
//       let unitArr = unit[key];
//       let type = unitArr[0];
//       if (value > 1 && unitArr.length > 1) {
//         type = unitArr[1];
//       }
//       msg = msg.replace(`{${unitTarget}}`, `${type}`);
//     }

//     msg = msg.replace(`{${numTarget}}`, `${value}`);
//   }
//   return msg;
// }

export function i18nPluralization(path, limit, unit) {
  let limitKeys = Object.values(limit).filter(num => num > 0);
  let msg;
  let param = {};
  for (const [key, value] of Object.entries(limit)) {
    if (value == 0) continue;
    let numTarget = "num";
    let unitTarget = "unit"
    if (limitKeys.length > 1) {
      numTarget += `-${key}`;
      unitTarget += `-${key}`;
    }
    if (unit) {
      let unitArr = unit[key];
      let type = unitArr[0];
      if (value > 1 && unitArr.length > 1) {
        type = unitArr[1]
      }
      param[unitTarget] = type;
    }
    param[numTarget] = value;
  }
  if (process.env.NODE_ENV == "production") {
    msg = $i18n.global.t(path).split("|")[limitKeys.length - 1];
    for (const [paramKey, paramVal] of Object.entries(param)) {
      msg = msg.replace(`{${paramKey}}`, `${paramVal}`);
    }
  } else {
    msg = i18n.t(path, param)
  }
  return msg;
}

export function checkStrLang(str) {
  return encodeURI(str).split(/%..|./).length - 1 == str.length ? constants.locale.en : constants.locale.ch
}

export function defaultFileObj() {
  return {
    type: null,
    file: {
      name: null,
      key: null
    }
  }
}

export function defaultNameObj() {
  return {
    title: 0,
    firstName: null,
    surname: null,
    fullName: null
  }
}

export function defaultPersonObj() {
  return {
    name: defaultNameObj(),
    title: null,
    department: null,
    mobile: null,
    email: null,
    signature: defaultFileObj()
  }
}

export function defaultAddressObj(hasDistrictAndRegion) {
  let addr = {
    address1: null,
    address2: null,
    address3: null
  }
  if (hasDistrictAndRegion) {
    addr.district = null;
    addr.area = null;
  }
  return addr
}

export function getOptionsLabel(options, value) {
  for (let item of options) {
    if (item.value == value) {
      return item.label;
    }
  }      
}

export function sleep(duration) {
  return new Promise(resolve => setTimeout(resolve, duration))
}